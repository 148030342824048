@import './styles/colors.module';
@import './styles/breakpoints.module';

.container {
  height: calc(100vh - 245px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.errorCode {
  padding: 0 20px;
  font-size: 24px;
  font-weight: 500;
  vertical-align: top;
  line-height: 49px;
  border-right: 1px solid $gray;
}
